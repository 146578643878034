export const formatCurrency = (value) => {
    return `$${parseFloat(value).toFixed(2)}`;
};

//Indices 0 decimal places
//Stocks/Commodities/Bonds 2 decimal places
//Currencies 4 decimal places
export const formatPrice = (value, type=0, decimalPlaces = 2) => {
  //console.log("convertToGermanFormat decimalPlaces",decimalPlaces);
  
  if (value === undefined || value === null || value === "") {
    return ""; // Return an empty string for invalid input
  }

  // Ensure the value is a number
  const numericValue = parseFloat(value);

  if(type){
    decimalPlaces = getDecimalPointFromType(type);
  }

  if(numericValue>1000){
    decimalPlaces=0
  }

  // Format the value based on the number of decimal places
  const price = numericValue.toLocaleString("de-DE", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  return price;
};

export const getDecimalPointFromType = (type) => {
  //console.log("getDecimalPointFromType type",type);
  if (type === 1) {
    return 0;
  } else if ([2, 4, 5].includes(type)) {
    return 2;
  } else if (type === 3) {
    return 4;
  }
  
  return 0;
};


  